<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg px-3">
                    <span style="font-family: Ubuntu, Arial, sans-serif !important; text-align: justify;" v-html="philosophy.content" />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import configService from '@/services/config'
    export default {
        data() {
            return {
                philosophy: null
            }
        },
        created() {
            this.getPhilosophy()
        },
        methods: {
            getPhilosophy() {
                configService.getConfigField('philosophy').then(resp => {
                    this.philosophy = resp.data
                })
            }
        }
    }
</script>
